.barra-titulo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: inherit;

}

.barra-titulo .item-barra {
    background-color: #fff;
    border: 1px solid #ddd;
    padding: 15px;
    font-weight: 700;
    border-radius: 5px;

    font-size: 1rem;
    line-height: 1.5;
    color: #212529;
    flex-grow: 1;
    margin: 0 0px 15px 10px;
    text-align: center;
}

.barra-titulo .item-barra:first-child {
    width: 70%;
    margin-left: 0;
    text-align: left;
}

.barra-titulo .item-barra.btn {
    max-width: 80px;
}

.barra-titulo .item-barra.btn svg {
    cursor: pointer;
}

.barra-titulo .item-barra.btn svg:hover {
    color: #f19d1f;
}


.barra-titulo .item-barra.btn:hover {
    transition: background-color 0.2s;
}
.mobileBarraExtra{
    display: none;
}


@media (max-width:767px) {
    .barra-titulo .item-barra {
        padding: 10px;
        font-weight: 700;
        border-radius: 5px;
        margin-bottom: 20px;
        font-size: 1.2rem;
    }
    .btn{
        display: block;
    }
    .barra-titulo:nth-child(2) .item-barra:first-child{
        display: none;
    }
    .barra-titulo .item-barra:nth-child(2){
        display: none;
    }

    .barra-titulo:first-child{
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 100%;
        width: 100%;
    }
    .barra-titulo .item-barra:first-child{
        display: flex;
        justify-content: space-between;
    }
    .barra-titulo .item-barra span{
        font-weight: 500;
        font-size: 13px;
        display: flex;
        align-items: center;
        width: 30%;
    }
    .mobileBarraExtra{
        display: flex;
    }
}
