.container-modal-lateral{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 62vh;
}

.interacao-modal-lateral{
  font-size: 15px;
}

.interacao-modal-lateral .botoes-modal-lateral{
  height: 20px;
  width: 200px;
  outline: none;
  text-decoration: none;
  border: none;
  background-color: #fff;
  display: flex;
  margin-top: 10px;
}

.interacao-modal-lateral .botoes-modal-lateral:hover{
  opacity: 0.8;
  transition: 0.2s;
}

.interacao-modal-lateral .botoes-modal-lateral:focus{
  font-weight: bold;
}

.footer-modal-lateral{
  margin-left: 25px;
}

.footer-modal-lateral .botao-enviar-convite{
  background-color: #0A71b2;
  outline: none;
  align-items: center;
  justify-content: center;
  text-decoration: none;

  display: flex;

  color: #fff;
  border-radius: 5px;
  border: none;
  margin-left: 5px;
  width: max-content;
  height: 34px;
  padding: 0px 5px 0px 5px;
}

