* {
  padding: 0;
  margin: 0;
  outline: 0;
  box-sizing: border-box;
}

html, body, #root{
  min-height: 100%;
}

body{
  font-family: 'Poppins', Arial, Helvetica, sans-serif !important;
  font-size: 14px;
  background-color: #f2f3f8 !important;
}

.wrapper{
  display: flex;
  flex-direction: row;
  max-width: 100%;
  justify-content: space-between;
}

.content{
    width: calc(100% - 50px);
    padding: 15px;
    margin-top: 55px;
    overflow-x: hidden;
}

/* Variaveis */

:root{
    /* Cores */
    --dommus-color:#183b64;
    --dommus-color-2:#f19d1f;
    --dommus-color-secondary:#64B5F6;
    --dommus-color-cinza:#868aa8;
    --dommus-color-green:#4CAF50;
    --dommus-color-red:#f44336;
    --dommus-color-dark:#353252;
    --dommus-shadow:0 2px 4px rgba(0,0,0,0.1);
    --dommus-b-radius: 5px;
    --dommus-overflow-width:6px;
    --dommus-overflow-height:6px;


    /*  */
    --smooth:200ms ease-in-out;
}

*::-webkit-scrollbar-track
{
    background-color: #ddd;
}
*::-webkit-scrollbar
{
    width:var(--dommus-overflow-width);
    height: var(--dommus-overflow-height);
    background-color: #ddd;
    border-radius: 10px;
}

body::-webkit-scrollbar {
    width: 8px;
}
*::-webkit-scrollbar-thumb
{
    background-color: var(--dommus-color);
    border-radius: 10px;
}

.form-control .form-control-color{
    padding: .375rem 14rem !important;
}
.form-group .react-datepicker-wrapper{
    width: 100%;
}

.icon-em-construcao{
    cursor: not-allowed;
    color: var(--dommus-color-cinza);
    opacity: 40%;
}

.buttonNovaOportunidade.dropup {
    background: none;
}
.buttonNovaOportunidade.dropup .dropdown-toggle::after {
    content: none;
}
.buttonNovaOportunidade.dropup .btn {
    background-color: transparent;
}

.buttonNovaOportunidade .btn.btn-primary {
    background-color: #0a71b2;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 30px;
    bottom: 30px;
    padding: 15px;
    font-size: 35px;
    border-radius: 100%;
    cursor: pointer;
}

@media (max-width:767px) {
    .content{
        width: 100%;
    }
}
