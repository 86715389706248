.menu-mobile{
    position: absolute;
    background: white;
    width: 100vw;
    height: calc(100vh - 55px);
    top: 55px;
    left: 0px;
    z-index: 200;
    color: #4D4B4B;

    .header{
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 2px;
        border-bottom: 1px solid gainsboro;        
        padding: 10px;

        .user-name-mobile{
            font-weight: 600;
            font-size: 1.2rem;
        }

        .client-name-mobile{
            font-size: 0.9rem;
            text-transform: uppercase;
        }

    }
    .itens-menu{
        display: flex;
        height: 100%;
        flex-direction: column;
        padding: 20px 10px;        

        .itens-principais{
            display: flex;
            flex-direction: column;
            flex-basis: 70%;
            gap: 10px;
        }

        .itens-footer{
            display: flex;
            flex-direction: column;
            flex-basis: 15%;
            gap: 10px;
            border-top: solid 1px gainsboro;
            padding-top: 10px;
        }
        
        .item-menu{
            display: flex;
            flex-direction: row;
            gap: 10px;
            width: 100%;
            align-items: center;
            font-size: 1.3rem;
        }
    }

    .copy{
        display: flex;
        width: 100%;
        justify-content: center;
        font-size: 1rem;      
        gap: 10px;  
        align-items: center;
    }

}