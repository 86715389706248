.formCadastro .botao-modal-salvar-usuario {
  background-color: #4CAF50;
  outline: none;
  align-items: center;
  justify-content: center;
  padding: 5px;
  text-decoration: none;

  display: flex;
  width: 100px;

  color: #fff;
  border-radius: 5px;
  border: none;
  margin-left: auto;

  &.disabled {
    background-color: #9ccd9d;
    pointer-events: none;
  }
}

.formCadastro .botao-abrir-modal-lateral{
  outline: none;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  border: none;
  background-color: #fff;
  padding: 5px;
}

.formCadastro .botao-modal-fechar{
  background-color: #F44336;
  outline: none;
  align-items: center;
  justify-content: center;
  padding: 5px;
  text-decoration: none;

  display: flex;
  width: 100px;

  color: #fff;
  border-radius: 5px;
  border: none;
  margin-left: auto;
}

.formCadastro .botao-modal-salvar-usuario:hover {
  opacity: 0.8;
  transition: 0.2s;
}

.formCadastro .botao-modal-fechar:hover {
  opacity: 0.8;
  transition: 0.2s;
}

.formCadastro .botao-modal-salvar-usuario svg{
  margin-right: 10px;
}

.conteudo {
  max-height: 80vh;
}
